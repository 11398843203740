<template>
  <div class="head-portrait">
    <div>
      <img :src="userInfo.imgUrl" alt="" />
      <!-- <img :src="userInfo.imgUrlOnline" alt="" v-else> -->
    </div>

    <div
      style="
        color: rgb(255, 255, 255);
        margin-left: 70px;
        text-align: left;
        width: 80px;
      "
    >
      <span style="display: block; font-size: 14px">{{ userInfo.level }}</span>
      <span style="display: block; font-size: 12px">{{
        hidePhone(userInfo.phone)
      }}</span>
      <span style="display: block; font-size: 12px; opacity: 0.8">{{
        userInfo.lineStatus
      }}</span>
      <span style="display: block; font-size: 12px; opacity: 0.8">剩余次数：{{ this.$store.state.userInfo.chatCount }}</span>
    </div>

    <div>
      <el-button
        class="loginBtn"
        type="primary"
        round
        @click="showLogin"
        v-if="userInfo.lineStatus == '未登录'"
        >登录</el-button
      >
      <el-button class="logoutBtn" type="info" round @click="logout" v-else
        >退出</el-button
      >
    </div>

    <!-- <div v-if="loginVisible">
        <LoginDialog></LoginDialog>
    </div> -->
    <el-dialog :visible.sync="loginVisible" append-to-body>
      <LoginDialog
        ref="LoginDialog"
        @loginFun="loginFun"
        @getLocalStorageUser="getLocalStorageUser"
      ></LoginDialog>
    </el-dialog>
  </div>
</template>

<script>
import LoginDialog from "@/view/login/login.vue";
import { getUserById } from "@/api/getLogin";
import { getToken } from "@/util/auth";
export default {
  components: {
    LoginDialog,
  },
  data() {
    return {
      loginVisible: false,
      userInfo: {
        level: "游客",
        lineStatus: "未登录",
        phone: "",
        ip: "",
        chatCount: "",
        imgUrl: require("@/assets/img/head_portrait.jpg"),
      },
    };
  },
  created() {},
  methods: {
    getLocalStorageUser() {
      let userId = localStorage.getItem("MJ_USER_ID");
      console.log(userId);
      if (userId) {
        let param = {
          userId: userId,
        };
        getUserById(param)
          .then((res) => {
            if (res.code == 200) {
              this.userRest();
              //游客级别
              if (res.data.level == "游客") {
                this.userInfo.lineStatus = "未登录";
              }
              //普通用户/vip级别
              else if (
                res.data.level == "普通用户" ||
                res.data.level.includes("vip")
              ) {
                this.userInfo.phone = res.data.phone;
                this.userInfo.imgUrl = require("@/assets/img/head_portrait_online.jpg");
                this.userInfo.lineStatus = "";
                // this.userInfo.vipEndTime = res.data.vipEndTime;
              }
              // else if (res.data.level.includes('vip')){

              // }
              this.userInfo.ip = res.data.ip;
              this.userInfo.chatCount = res.data.chatCount;
              this.userInfo.level = res.data.level;
              // this.$cookies.set("UserId", userId,246060*720);
              localStorage.setItem("MJ_USER_ID", res.data.id);

              this.$store.commit("settUserInfo", res.data);
              
      this.$emit("resetChat");
            } else {
              this.$message({ message: res.message, type: "warning" });
            }
          })
          .finally(function () {});
      }
    },
    hidePhone(phone) {
      if (phone) {
        return phone.replace(phone.substr(3, 4), "****");
      }
      return "";
    },
    showLogin() {
      this.loginVisible = true;
      this.$nextTick(() => {
        this.$refs.LoginDialog.resetForm();
      });
    },
    loginFun() {
      this.$emit("resetChat");
      this.loginVisible = false;
    },
    userRest() {
      this.userInfo = {
        level: "游客",
        lineStatus: "未登录",
        phone: "",
        ip: "",
        chatCount: "",
        imgUrl: require("@/assets/img/head_portrait.jpg"),
      };
    },
    logout() {
      localStorage.removeItem("MJ_USER_ID");
      this.$router.go(0);
    },
  },
  // props: {
  //     userInfo:{ default:{}}
  // }
};
</script>

<style lang="less" scoped>
.el-dialog__wrapper {
  /deep/.el-dialog {
    border-radius: 20px;
    background-color: #161616;
    margin-top: 15vh !important;
    width: 430px !important;
    .el-dialog__header {
      .el-dialog__headerbtn {
        font-size: 16px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.head-portrait {
  width: 225px;
  height: 65px;
  border-radius: 20px;
  padding: 10px;
  // border-radius: 50%;
  // border: 2px solid rgb(137,140,151);
  border: 2px solid rgb(19, 26, 41);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(19, 26, 41);
  // &::before {
  //     content: '';
  //     width: 15px;
  //     height: 15px;
  //     z-index: 1;
  //     display: block;
  //     border-radius: 50%;
  //     background-color: rgb(144,225,80);
  //     position: absolute;
  //     right: 0;
  // }
  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    // padding: 2px;
    box-sizing: border-box;
    position: absolute;
    left: 20%;
    top: 50%;
    transform: translate(-50%, -50%);
    // vertical-align: middle;
  }
}
.logoutBtn {
  background: #909399;
  border: 1px solid #909399;
  // width: 50px;
  height: 30px;
  cursor: pointer;
  line-height: 5px;
  border-radius: 10px;
  font-size: 12px;
  color: #fff;
}
.loginBtn {
  background: #0081ff;
  border: 1px solid #0081ff;
  // width: 50px;
  height: 30px;
  cursor: pointer;
  line-height: 5px;
  border-radius: 10px;
  font-size: 12px;
  color: #fff;
  // letter-spacing:2px;
  // span {
  //     letter-spacing:1px;
  //     color: #000;
  //     text-align: center;
  //     display:block
  // }
}
</style>