<template>
  <div class="nav">
    <div class="nav-menu-wrapper">
      <div style="display: flex;align-items: center;">
        <img src="@/assets/img/logo.png" width="60px" height="60px" /><h4 style="color: white;">爱炫图 AI绘画</h4>
      </div>
      <ul class="menu-list">
        <li
          v-for="(item, index) in menuList"
          :key="index"
          :class="{ activeNav: item.order == current,activeNotNav: item.order != current }"
          @click="changeMenu(item.order)"
        >
          <div class="block"></div>
          <span class="iconfont" :class="item.icon"></span>
          <span style="display: inline-block;margin-left: 15px;vertical-align: middle;">{{ item.lable }}</span>
        </li>
      </ul>

      <div class="link-top"></div>
      <ul class="menu-list">
        <li
          v-for="(item, index) in menuList2"
          :key="index"
          :class="{ activeNav: item.order == current,activeNotNav: item.order != current }"
          @click="changeMenu(item.order);goBottomMenu(item.order);"
        >
          <div class="block"></div>
          <span class="iconfont" :class="item.icon"></span>
          <span style="display: inline-block;margin-left: 15px;vertical-align: middle;">{{ item.lable }}</span>
        </li>
      </ul>
    </div>
    <div class="own-pic">
        <HeadPortrait  ref="HeadPortrait" @resetChat="resetChat"></HeadPortrait>
    </div>

    <el-dialog
        title="不懂如何绘图？我来教你！一文看懂详细教程！"
        append-to-body
        :visible.sync="tutorialVisible" 
        width="820px"
        top="3vh"
        @close="closeDialog()">
        <div style="height: calc(95vh - 160px);overflow-y: auto;">
          <Tutorial></Tutorial>
        </div>
    </el-dialog>

    <el-dialog
        title="成为尊贵的会员，享受更多权益"
        append-to-body
        :visible.sync="upgradeVisible" 
        width="450px"
        @close="closeDialog()">
        <!-- <div style="height: calc(90vh - 50px);overflow-y: auto;"> -->
          <Upgrade v-if="upgradeVisible" @upgradeLoad = visitLoad @closeUpgrade = closeUpgrade></Upgrade>
        <!-- </div> -->
          
    </el-dialog>
  </div>
</template>

<script>
import Tutorial from "@/view/pages/tutorial/index";
import Upgrade from "@/view/pages/upgrade/index";
import HeadPortrait from "./HeadPortrait.vue";
import { getToken } from '@/util/auth';
import { visitorLogin } from "@/api/getLogin";

export default {
  components: {
    HeadPortrait,
    Tutorial,
    Upgrade,
  },
  data() {
    return {
      step1imgUrl:require("@/assets/img/tutorial/step1.png"),
      step2imgUrl:require("@/assets/img/tutorial/step2.png"),
      step3imgUrl:require("@/assets/img/tutorial/step3.png"),
      menuList: [
        {
          icon:"icon-huihua",
          lable:"快速绘画",
          order:1,
        },
        {
          icon:"icon-gengduotupian",
          lable:"多图融合",
          order:2,
        },
        {
          icon:"icon-tupian",
          lable:"图生文",
          order:3,
        },
        // {
        //   icon:"icon-fanyi",
        //   lable:"翻译",
        // },
      ],
      menuList2: [
        {
          icon:"icon-icon",
          lable:"升级VIP",
          order:5,
        },
        {
          icon:"icon-xinshoubangzhu",
          lable:"新手指南",
          order:4,
        },
      ],
      // userInfo:{
      //   level:"游客",
      //   lineStatus:"未登录",
      //   iphone:"",
      //   ip:"",
      //   chatCount:"",
      //   imgUrl:require('@/assets/img/head_portrait.jpg'),
      // },
      current: 1,
      tutorialVisible:false,
      upgradeVisible:false,
    };
  },
  mounted(){
    this.visitLoad();
  },
  methods: {
    visitLoad(){
      localStorage.setItem('model', '1');
      //生成游客记录
      let userId = localStorage.getItem('MJ_USER_ID');
      if(!userId){
        visitorLogin().then((res) => {
          if(res.code == 200){
            if(res.data.id){
              // this.$cookies.set("UserId", userId,246060*720);
              localStorage.setItem('MJ_USER_ID', res.data.id);
              console.log(res.data.id);
              this.$refs.HeadPortrait.getLocalStorageUser();
            }
          }else{
            this.$message({message: res.message,type: "warning",});
          }
        }).finally(function(){
        });
      }else{
        this.$refs.HeadPortrait.getLocalStorageUser();
      }
    },
    changeMenu(index) {
      console.log(index);
      switch (index) {
        case 1:
          localStorage.setItem('model', '1');
          this.$message({message: "您已切换至专业绘画模式 🥳~🥳~",type: "success",});
          break;
        case 2:
          this.$message({message: "您已切换至多图绘画模式 🥳~🥳~",type: "success",});
          localStorage.setItem('model', '3');
          break;
        case 3:
          this.$message({message: "您已切换至图生描述模式 🥳~🥳~",type: "success",});
          localStorage.setItem('model', '4');
          break;
      }

      this.current = index;
    },
    goTutorial(){
      // this.$router.push("/Tutorial");
      this.tutorialVisible = true;
    },
    goUpgrade(){
      this.upgradeVisible = true;
      // this.$nextTick(() => {
      //   this.$refs.upgrade.openUpgrade();
      // })
    },
    resetChat(){
      this.$emit('resetChat');
    },
    goBottomMenu(order){
      if(order == '4'){
        this.goTutorial();
      }
      if(order == '5'){
        this.goUpgrade();
      }
    },
    closeDialog(){
      this.current = 1;
      localStorage.setItem('model', '1');

    },
    upgradeLoad(){
      this.$router.go(0);

    },
    closeUpgrade(){
      this.upgradeVisible = false;

    }

    // userRest(){
    //   this.userInfo = {
    //     level:"游客",
    //     lineStatus:"未登录",
    //     iphone:"",
    //     ip:"",
    //     chatCount:"",
    //     imgUrl:require('@/assets/img/head_portrait.jpg'),
    //   };
    // },
  },
};
</script>

<style lang="scss" scoped>

::-webkit-scrollbar {
/*隐藏滚轮*/
display: none;
}
/*中间的过度的横线*/
.link-top {
  width: 250px;
  height: 1px;
  border-top: solid #131A29 3px;
  margin: 35px 5px;
}

.nav {
  width: 100%;
  height: 100vh;
  // position: relative;
  border-radius: 20px 0 0 20px;
  .nav-menu-wrapper {
    position: absolute;
    top: 2vh;
    // transform: translate(0, -50%);
    .menu-list {
      margin-left: 10px;

      li {
        margin: 30px 0 0 30px;
        list-style: none;
        cursor: pointer;
        position: relative;
        .block {
          background-color: rgb(29, 144, 245);
          position: absolute;
          left: -20px;
          width: 6px;
          height: 25px;
          transition: 0.5s;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          opacity: 0;
        }
        &:hover {
          span {
            color: rgb(29, 144, 245);
          }
          .block {
            opacity: 1;
          }
        }
      }
    }
  }
  .own-pic {
    position: absolute;
    bottom: 15px;
    margin-left: 5px;
  }
}
.activeNav {
  span {
    color: rgb(29, 144, 245);
  }
  .block {
    opacity: 1 !important;
  }
}
.activeNotNav{
  span {
    color: rgb(255, 255, 255);
  }
}
</style>