import base from './index'
let axios = base.axios
// let baseUrl = base.baseUrl

// 获取好友
// export const getFriend = params => {
//     return axios({
//       method: 'post',
//       baseURL: `${baseUrl}/friend/friendList`,
//       data: params
//     }).then(res => res.data)
//   }

//   // 获取聊天信息
// export const getChatMsg = params => {
//   return axios({
//     method: 'post',
//     baseURL: `${baseUrl}/friend/chatMsg`,
//     data: params
//   }).then(res => res.data)
// }

// 发送信息至MJ(文生图)
export const fontCreatePic = params => {
  return axios({
    method: 'post',
    baseURL: `/mj/submit/imagine`,
    data: params
  }).then(res => res.data)
}

// 发送多图至MJ(混图)
export const picCreatePic = params => {
  return axios({
    method: 'post',
    baseURL: `/mj/submit/blend`,
    data: params
  }).then(res => res.data)
}

// 发送图至MJ获取描述(描述)
export const picCreateDes = params => {
  return axios({
    method: 'post',
    baseURL: `/mj/submit/describe`,
    data: params
  }).then(res => res.data)
}

// 绘图变化
export const imageChange = params => {
  return axios({
    method: 'post',
    baseURL: `/mj/submit/action`,
    data: params
  }).then(res => res.data)
}


// 根据任务id查询任务
export const fetchTaskById = params => {
  return axios({
    method: 'get',
    baseURL: `/mj/task/${params.id}/fetch`,
    params: params
  }).then(res => res.data)
}

// 上传图片至oss
export const uploadOssImg = params => {
  return axios({
    method: 'get',
    baseURL: `api/file/ossImg`,
    params: params
  }).then(res => res.data)
}

// 将记录保存起来
export const addChatRecord = params => {
  return axios({
    method: 'post',
    baseURL: `/api/file/addChatRecord`,
    data: params
  }).then(res => res.data)
}

// 获取绘画记录
export const getDrawRecordList = params => {
  return axios({
    method: 'get',
    baseURL: `/api/file/getDrawRecordList`,
    params: params
  }).then(res => res.data)
}

// 删除绘画记录
export const deleteRecord = params => {
  return axios({
    method: 'get',
    baseURL: `/api/file/deleteRecord`,
    params: params
  }).then(res => res.data)
}

// 获取支付码
export const getUnified = params => {
  return axios({
    method: 'post',
    baseURL: `/api/wxPay/unified`,
    data: params
  }).then(res => res.data)
}

// 获取短信验证码
export const getSmsCode = params => {
  return axios({
    method: 'get',
    baseURL: `/api/sms/send`,
    params: params
  }).then(res => res.data)
}


// 获取聊天信息
export const chatgpt = params => {
  return axios({
    method: 'post',
    url: `https://api.openai.com/v1/completions`,
    data: params,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer xxxxx`,
    }
  }).then(res => res.data)
}