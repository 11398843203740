import base from './index'
let axios = base.axios

  // 登录
  export const login = params => {
    return axios({
      method: 'post',
      baseURL: `/api/auth/login`,
      data: params
    }).then(res => res.data)
  }

  // 游客访问
  export const visitorLogin = params => {
    return axios({
      method: 'post',
      baseURL: `/api/auth/visitorLogin`,
      data: params
    }).then(res => res.data)
  }

  // 根据用户id获取用户信息
  export const getUserById = params => {
    return axios({
      method: 'get',
      baseURL: `api/auth/getUserById`,
      params: params
    }).then(res => res.data)
  }
  
  // 根据用户id更改用户信息
  export const updateUserchatCount = params => {
    return axios({
      method: 'get',
      baseURL: `api/auth/updateUserchatCount`,
      params: params
    }).then(res => res.data)
  }

