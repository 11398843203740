<template>
  <div class="chat-window">
    <div class="botoom">
      <!-- <img src="https://xuanchi-mj.oss-accelerate.aliyuncs.com/2023/09/27/9b68cf48-9864-459f-9701-996d98db7efd.png" /> -->
      <div class="chat-content" ref="chatContent">
        <div
          class="chat-wrapper"
          v-for="(item, index) in chatList"
          :key="item.id"
        >
          <div class="chat-friend" v-if="item.uid !== '1001'">
            <div class="info-time">
              <img :src="item.headImg" alt="" />
              <span>{{ item.name }} </span>
              <span>{{ item.time }}</span>
              <div style="margin-left: 52px; width: 70%">
                <pre
                  style="white-space: normal; word-break: break-all"
                  v-if="item.cueWord"
                ><p style="display: inline-block;font-weight: 900;" >{{ item.cueWord }}</p><p style="display: inline-block;" > ({{ item.status!='SUCCESS'?(item.progress?item.progress:"Wating to start"):getSpeedModel() }})</p></pre>
              </div>
            </div>
            <div class="chat-text">
              <!-- <template v-if="isSend && index == chatList.length - 1"> -->
              <!-- <pre><span class="flash_cursor"></span></pre> -->
              <!-- </template> -->
              <template>
                <pre
                  style="margin-left: 40px"
                ><span v-if="item.prompt&&item.action=='DESCRIBE'">{{ item.prompt }}</span></pre>

                <img
                  :src="item.pic"
                  alt="加载中.."
                  @click="showBigImage(item.pic)"
                  @contextmenu.prevent="onContextmenu($event, item)"
                  v-if="item.pic"
                  :style="imgStyle()"
                />

                <img
                  :src="item.pic2"
                  alt="加载中.."
                  @click="showBigImage(item.pic2)"
                  v-if="item.pic2"
                  style="width: 350px; height: 350px; margin-left: 15px"
                />

                <div v-if="item.action" style="width: 400px; margin-left: 35px">
                  <el-button
                    size="small"
                    v-for="(btu, index) in item.buttons"
                    v-if="btu"
                    :style="butStyle(item, btu)"
                    @click="UChange(btu, item)"
                  >
                    <span>{{ btu.label }}</span>
                    <span>{{ btu.emoji }}</span>
                  </el-button>
                </div>

                <div>
                  <div
                    style="width: 48%; margin-left: 40px; float: left"
                    v-if="item.action == 'FORSHADOW'"
                  >
                    <el-button
                      size="small"
                      v-if="item.pic"
                      style="
                        color: white;
                        background-color: #4e5058;
                        border-color: #4e5058;
                        width: 90px;
                      "
                      @click="insertBaseUrl(item)"
                      >插入地址</el-button
                    >
                  </div>
                </div>

                <div>
                  <div
                    style="width: 48%; margin-left: 40px; float: left"
                    v-if="item.action == 'FORSHADOWDES'"
                  >
                    <el-button
                      size="small"
                      v-if="item.pic"
                      style="
                        color: white;
                        background-color: #4e5058;
                        border-color: #4e5058;
                        width: 90px;
                      "
                      @click="insertBaseUrlDes(item)"
                      >插入地址</el-button
                    >
                  </div>
                </div>

                <div>
                  <div
                    style="width: 45%; margin-left: 40px; float: left"
                    v-if="item.action == 'FORSHADOWS'"
                  >
                    <el-button
                      size="small"
                      v-if="item.pic"
                      style="
                        color: white;
                        background-color: #4e5058;
                        border-color: #4e5058;
                        width: 90px;
                      "
                      @click="insertBaseUrl1(item)"
                      :disabled="insertBaseAble1"
                      >插入地址</el-button
                    >
                  </div>
                  <div
                    style="width: 46%; float: right"
                    v-if="item.action == 'FORSHADOWS'"
                  >
                    <el-button
                      size="small"
                      v-if="item.pic"
                      style="
                        color: white;
                        background-color: #4e5058;
                        border-color: #4e5058;
                        width: 90px;
                      "
                      @click="insertBaseUrl2(item)"
                      :disabled="insertBaseAble2"
                      >插入地址</el-button
                    >
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div style="position: fixed; bottom: 110px; right: 1%">
          <el-radio-group v-model="speedModel" size="medium">
            <el-radio-button label="放松" class="relaxBtn"></el-radio-button>
            <el-radio-button label="快速" class="fastBtn"></el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="chatInputs">
        <!-- 上传图片按钮 -->
        <div class="boxinput" @click="showUpload">
          <img src="@/assets/img/jia.png" alt="" />
        </div>

        <!-- 发送按钮 -->
        <input
          class="inputs"
          v-model="inputMsg"
          @keyup.enter="sendText"
          :readonly="this.model == '3' || this.model == '4'"
          :placeholder="
            this.model == '1'
              ? '输入Midjourney提示词'
              : '请点击左侧加号上传图片'
          "
        />
        <el-button class="send boxinput" @click="sendText">
          <img src="@/assets/img/emoji/rocket.png" alt="" />
        </el-button>
      </div>

      <el-dialog
        append-to-body
        title="垫图"
        :visible.sync="onePicVisible"
        width="20%"
      >
        <el-upload
          class="avatar-uploader"
          style="text-align: center"
          action=""
          :on-change="handleAvatarSuccess"
          accept="image/jpg,image/png,image/jpeg"
          :show-file-list="false"
          :auto-upload="false"
        >
          <img
            v-if="this.onePicUrl"
            :src="this.onePicUrl"
            width="150px"
            height="150px"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon" />
        </el-upload>

        <!-- <el-upload
          style="text-align: center;"
          class="avatar-uploader"
          action=""
          list-type="picture"
          :file-list="fileList"
          :show-file-list="false"
          :on-change="handleAvatarSuccess">
          <img v-if="this.onePicUrl" :src="this.onePicUrl" width="150px" height="150px" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload> -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="onePicVisible = false">取 消</el-button>
          <el-button type="primary" @click="sureOnePic">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog
        append-to-body
        title="混图"
        :visible.sync="twoPicVisible"
        width="25%"
      >
        <div style="width: 100%; text-align: center">
          <el-upload
            class="avatar-uploader"
            style="display: inline-block"
            action=""
            :on-change="handletwoAvatarSuccess"
            accept="image/jpg,image/png,image/jpeg"
            :show-file-list="false"
            :auto-upload="false"
          >
            <img
              v-if="this.twoPicUrl"
              :src="this.twoPicUrl"
              width="150px"
              height="150px"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>

          <el-upload
            class="avatar-uploader"
            style="display: inline-block; margin-left: 20px"
            action=""
            :on-change="handletwoAvatarSuccess2"
            accept="image/jpg,image/png,image/jpeg"
            :show-file-list="false"
            :auto-upload="false"
          >
            <img
              v-if="this.twoPicUrl2"
              :src="this.twoPicUrl2"
              width="150px"
              height="150px"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>

          <!-- <el-upload
            style="display: inline-block;"
            class="avatar-uploader"
            name="files"
            action="api/file/upload"
            :file-list="twofileList"
            :show-file-list="false"
            :on-success="handletwoAvatarSuccess">
            <img v-if="this.twoPicUrl" :src="this.twoPicUrl" width="150px" height="150px" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload> -->

          <!-- <el-upload
            style="display: inline-block;margin-left:20px"
            class="avatar-uploader"
            name="files"
            action="api/file/upload"
            :file-list="twofileList2"
            :show-file-list="false"
            :on-success="handletwoAvatarSuccess2">
            <img v-if="this.twoPicUrl2" :src="this.twoPicUrl2" width="150px" height="150px" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload> -->
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="twoPicVisible = false">取 消</el-button>
          <el-button type="primary" @click="sureTwoPic">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog
        append-to-body
        title="描述"
        :visible.sync="desPicVisible"
        width="20%"
      >
        <el-upload
          class="avatar-uploader"
          style="text-align: center"
          action=""
          :on-change="handledesAvatarSuccess"
          accept="image/jpg,image/png,image/jpeg"
          :show-file-list="false"
          :auto-upload="false"
        >
          <img
            v-if="this.desPicUrl"
            :src="this.desPicUrl"
            width="150px"
            height="150px"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon" />
        </el-upload>

        <!-- <el-upload
          style="text-align: center;"
          class="avatar-uploader"
          name="files"
          action="api/file/upload"
          :file-list="desfileList"
          :show-file-list="false"
          :on-success="handledesAvatarSuccess">
          <img v-if="this.desPicUrl" :src="this.desPicUrl" width="150px" height="150px" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload> -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="desPicVisible = false">取 消</el-button>
          <el-button type="primary" @click="sureDesPic">确 定</el-button>
        </span>
      </el-dialog>

      <BigImg
        :visible="photoVisible"
        :url="bigImgUrl"
        @closeClick="
          () => {
            photoVisible = false;
          }
        "
      ></BigImg>
    </div>
  </div>
</template>

<script>
import { animation, getdateTime } from "@/util/util";
import {
  fontCreatePic,
  picCreatePic,
  picCreateDes,
  fetchTaskById,
  imageChange,
  uploadOssImg,
  addChatRecord,
  getDrawRecordList,
  deleteRecord,
} from "@/api/getData";
import BigImg from "./bigImg";

import HeadPortrait from "@/components/HeadPortrait";
import Emoji from "@/components/Emoji";
import FileCard from "@/components/FileCard.vue";
import { getUserById, updateUserchatCount } from "@/api/getLogin";
import { words } from "@/util/bannedWords";

export default {
  components: {
    HeadPortrait,
    Emoji,
    FileCard,
    BigImg,
  },
  props: {
    frinedInfo: Object,
    default() {
      return {};
    },
  },
  watch: {
    frinedInfo() {
      this.getFriendChatMsg();
    },
  },
  data() {
    return {
      //真实base64地址
      onePicUrl: "", //垫图图片
      twoPicUrl: "", //混图图片1
      twoPicUrl2: "", //混图图片2
      desPicUrl: "", //描述图片

      // 1. 纯文字生图
      // 2. 单图加文字生图
      // 3. 两个图生图
      // 4. 图生描述
      model: "1",
      speedModel: "快速",

      //垫图图片数组
      fileList: [],
      //混图数组
      twofileList: [],
      twofileList2: [],
      //描述图数组
      desfileList: [],
      //垫图弹窗
      onePicVisible: false,
      //混图弹窗
      twoPicVisible: false,
      //描述弹窗
      desPicVisible: false,
      //是否发消息
      isSend: false,

      //图片放大
      photoVisible: false,
      bigImgUrl: "",

      insertBaseAble1: false,
      insertBaseAble2: false,
      //聊天数组
      chatList: [],
      //输入信息
      inputMsg: "",

      //控制是否是用户自己滑动滚动条
      isUserScrolling: false,

      //绘画是否完成
      finishDraw: true,

      //计算绘画耗时
      apiStartTime: null,
      apiEndTime: null,

      //用户信息
      userInfo: {},
      //绘画信息
      recordData: {},

      //图片长宽比
      widthProport: null,
      heightProport: null,
    };
  },
  watch: {
    // 监听内容变化，调用 onContentChange 方法
    // 请确保您的内容变化会触发这个 watch
    // 例如，通过异步操作更新数据后触发
    someData: "onContentChange",
  },
  created() {
    window.addEventListener("setItemEvent", (e) => {
      // console.log("监听："+e.newValue)
      if (e.key == "model") {
        this.model = e.newValue;
        this.inputMsg = "";

        //垫图图片数组
        this.fileList = [];
        //混图数组
        this.twofileList = [];
        this.twofileList2 = [];
        //描述图数组
        this.desfileList = [];
        this.getDrawRecord();
      }
    });

    // this.scrollToBottom();
  },
  mounted() {},
  methods: {
    onContextmenu(event, item) {
      console.log(event);
      console.log(item);
      this.$contextmenu({
        items: [
          {
            label: "下载原图",
            icon: "el-icon-view",
            onClick: () => {
              this.downloadFile(item);
            },
          },
          {
            label: "删除记录",
            icon: "el-icon-delete",
            onClick: () => {
              this.deleteRecord(item);
            },
          },
        ],
        event, // 鼠标事件信息
        zIndex: 3, // 菜单样式 z-index
        minWidth: 100, // 主菜单最小宽度
      });
      return false;
    },

    //下载绘画原图
    downloadFile(item) {
      const image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.download = new Date().getTime();
          a.href = url;
          a.click();
          a.remove();
          URL.revokeObjectURL(url);
        });
      };
      let imageUrl = item.pic;
      const url = imageUrl.substring(0, imageUrl.lastIndexOf("?"));
      image.src = url;
    },

    //获取历史绘画记录
    getDrawRecord() {
      let userId = localStorage.getItem("MJ_USER_ID");
      let param = {
        userId: userId,
        type: this.model,
      };
      if (userId) {
        getDrawRecordList(param).then((res) => {
          console.log(res);
          this.chatList = res.data;
          for (let item of this.chatList) {
            let btnsArr = item.buttons;
            if (btnsArr) {
              console.log(JSON.parse(btnsArr));
              item.buttons = JSON.parse(btnsArr);
            }
          }
          this.scrollBottom();
        });
      }
    },

    //删除某条对话记录
    deleteRecord(item) {
      let param = {
        id: item.id,
      };
      deleteRecord(param).then((res) => {
        this.getDrawRecord();
      });
    },

    // 将滚动条置于底部
    scrollBottom() {
      this.$nextTick(() => {
        this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
        console.log(
          this.$refs.chatContent.scrollTop,
          this.$refs.chatContent.scrollHeight
        );
      });
    },
    // 在内容变化时调用此方法以保持滚动条在底部
    onContentChange() {
      // 如果用户没有手动滚动，则将滚动条置于底部
      if (!this.isUserScrolling) {
        this.scrollBottom();
      }
    },
    // 监听滚动事件，检查用户是否手动滚动
    handleScroll() {
      // 用户手动滚动时不再自动滚动
      this.isUserScrolling = true;
    },

    //打开上传图片弹窗
    showUpload() {
      this.model = localStorage.getItem("model");
      this.twofileList = [];
      this.twofileList2 = [];
      this.fileList = [];
      this.desfileList = [];
      this.onePicUrl = "";
      this.twoPicUrl = ""; //混图图片1
      this.twoPicUrl2 = ""; //混图图片2
      this.desPicUrl = ""; //描述图片
      if (this.model == "1" || this.model == "2") {
        this.onePicVisible = true;
      }
      if (this.model == "3") {
        this.twoPicVisible = true;
      }
      if (this.model == "4") {
        this.desPicVisible = true;
      }
    },

    //返图样式
    imgStyle() {
      let style = {
        width: "350px",
        height: "350px",
        "margin-left": "40px",
      };
      if (this.widthProport && this.heightProport) {
        style = {
          width: "350px",
          height: ((350 * this.heightProport) / this.widthProport).toFixed(0),
          "margin-left": "40px",
        };
      }
      return style;
    },

    //按钮样式
    butStyle(item, btu) {
      // debugger
      // if(btu){
      let style = {};
      if (
        item.action == "IMAGINE" ||
        item.action == "VARIATION" ||
        item.action == "ZOOM" ||
        item.action == "BLEND" ||
        item.action == "DESCRIBE" ||
        item.action == "PAN"
      ) {
        style = {
          color: "white",
          "background-color": "#4E5058",
          "border-color": "#4E5058",
          width: "60px",
          margin: "5px",
        };
        if (btu.label == "Imagine all") {
          style = {
            display: "none",
          };
        }
      }
      if (item.action == "UPSCALE") {
        style = {
          color: "white",
          "background-color": "#4E5058",
          "border-color": "#4E5058",
          margin: "5px",
        };
      }
      return style;
      // }
    },

    // 垫图start
    handleAvatarSuccess(file, fileList) {
      this.getBase64(file.raw).then((res) => {
        this.onePicUrl = res;
      });
    },
    // 垫图end

    // 混图start
    handletwoAvatarSuccess(file, fileList) {
      this.getBase64(file.raw).then((res) => {
        this.twoPicUrl = res;
      });
    },

    handletwoAvatarSuccess2(file, fileList) {
      this.getBase64(file.raw).then((res) => {
        this.twoPicUrl2 = res;
      });
    },
    // 混图end

    // 描述图start
    handledesAvatarSuccess(file, fileList) {
      this.getBase64(file.raw).then((res) => {
        this.desPicUrl = res;
      });
    },
    // 描述图end

    // 获取图片转base64
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },

    //发送信息
    sendMsg(msgList) {
      this.chatList.push(msgList);
      this.scrollBottom();
    },

    //处理提示词及参数校验合法性
    handleHint() {},

    //获取窗口高度并滚动至最底层
    // scrollBottom() {
    //   this.$nextTick(() => {
    //     const scrollDom = this.$refs.chatContent;
    //     animation(scrollDom, scrollDom.scrollHeight - scrollDom.offsetHeight);
    //   });
    // },
    comparedate(endTime) {
      let startDateTime = new Date();
      let endDateTime = new Date(endTime);
      if (startDateTime.getTime() > endDateTime.getTime()) {
        return true;
      } else {
        return false;
      }
    },

    //发送信息
    sendText() {
      let userData = this.$store.state.userInfo;
      console.log(userData);
      if (this.inputMsg) {
        if (userData.chatCount == 0) {
          this.$message({
            message: "您的绘画次数已用完，升级为VIP可解锁更多次数哦~",
            type: "warning",
          });
          return;
        }
        for (let i in words) {
          if (this.inputMsg.includes(words[i])) {
            this.$message({
              message: "您的提示词中包含敏感词语，请检查哦~",
              type: "warning",
            });
            return;
          }
        }
        if (userData.level.includes("vip")) {
          if (this.comparedate(userData.vipEndTime)) {
            this.$message({
              message: "您的会员期限已到期，请及时续费哦~",
              type: "warning",
            });
            return;
          }
        }
        if (!this.finishDraw) {
          this.$message({
            message: "图片正在努力绘画中，请等待绘画完成后再次绘画~",
            type: "warning",
          });
          return;
        }

        this.apiStartTime = new Date();
        // if(words.includes(this.inputMsg)){
        // }

        //处理提示词及  --参数
        //找到第一个--位置，并把第一个--之前的字符认定为提示词
        if (this.inputMsg.includes("--")) {
          let promentIndex = this.inputMsg.indexOf("--");
          if (!this.inputMsg.substring(0, promentIndex).trim()) {
            this.$message({
              message: "您的绘画提示词不合法，请检查 ~",
              type: "warning",
            });
            return;
          }

          let inputArr = this.inputMsg.substring(promentIndex).split("--");
          for (let i in inputArr) {
            if (inputArr[i].includes("ar")) {
              let ar = inputArr[i];

              //找到ar所在位置
              let arIndex = ar.indexOf("ar") + 2;
              //找到冒号所在位置
              let maoIndex = ar.indexOf(":") + 1;
              //找到ar字符串结束所在位置
              let arAllIndex = ar.length;

              // let proportion = ar.substring(5,arAllIndex);
              // let proportArr = proportion.splice(":");

              this.widthProport = ar.substring(arIndex, maoIndex - 1).trim();
              this.heightProport = ar.substring(maoIndex).trim();

              console.log(this.widthProport);
              console.log(this.heightProport);
              console.log(inputArr);
              break;
            }
          }
        }

        this.inputMsg = this.inputMsg
          .replace("--fast", "")
          .replace("--relax", "")
          .replace("--turbo", "");
        if (this.speedModel == "快速") {
          this.inputMsg = this.inputMsg + " --fast";
        }
        if (this.speedModel == "放松") {
          this.inputMsg = this.inputMsg + " --relax";
        }

        let data = {
          base64Array: null,
          notifyHook: null,
          prompt: null,
          state: null,
        };
        if (this.model == "1") {
          //提示词 生图
          data = {
            base64Array: null,
            notifyHook: null,
            prompt: this.inputMsg,
            state: null,
          };
        }

        if (this.model == "2") {
          //提示词加图 生图
          let inputMsgArr = this.inputMsg.split(",");
          let inputMsg = "";
          for (let i = 1; i < inputMsgArr.length; i++) {
            inputMsg += inputMsgArr[i] + " ";
          }
          if (inputMsg.split(" ").join("").length == 0) {
            this.$message({ message: "提示词不能为空哦~", type: "warning" });
            return;
          }
          data = {
            base64Array: [this.onePicUrl],
            notifyHook: null,
            prompt: inputMsg,
            state: null,
          };

          this.model = "1";
        }

        if (this.model == "3") {
          //混图 生图
          data = {
            base64Array: [this.twoPicUrl, this.twoPicUrl2],
            notifyHook: null,
            remix: null,
            state: null,
          };
        }

        if (this.model == "4") {
          //描述 生字
          data = {
            base64: this.desPicUrl,
            notifyHook: null,
            state: null,
          };
        }

        this.loading = true;
        this.isSend = true;
        this.finishDraw = false;
        let chatGPT = {
          headImg: require("@/assets/img/head_portrait1.jpg"),
          name: "Midjourney Proxy",
          time: getdateTime(),
          msg: null,
          chatType: 0, //信息类型，0文字，1图片
          uid: "1002", //uid
          prompt: null,
          pic: null,
          pic2: null,
          showPic: null,
          showPic2: null,
          cueWord: this.inputMsg,
          progress: null,
          action: null,
          status: null,
          taskId: null,
          buttons: [],
        };
        this.inputMsg = "";
        this.recordData = data;
        this.sendMsg(chatGPT);

        //模式1和模式2MJ接口
        if (this.model == "1" || this.model == "2") {
          fontCreatePic(data).then((res) => {
            if (res.code == 1) {
              //获取回调图片
              this.getTaskById(res.result);
              if (this.speedModel == "快速") {
                //更改用户绘图可用次数
                this.computNum(userData);
              }
            } else {
              this.$message({ message: res.description, type: "warning" });
            }
          });
        }

        //模式3MJ接口
        if (this.model == "3") {
          picCreatePic(data).then((res) => {
            if (res.code == 1) {
              //获取回调图片
              this.getTaskById(res.result);

              if (this.speedModel == "快速") {
                //更改用户绘图可用次数
                this.computNum(userData);
              }
            } else {
              this.insertBaseAble1 = false;
              this.insertBaseAble2 = false;
              this.$message({ message: res.description, type: "warning" });
            }
          });
        }

        //模式4MJ接口
        if (this.model == "4") {
          picCreateDes(data).then((res) => {
            if (res.code == 1) {
              //获取回调图片
              this.getTaskById(res.result);

              if (this.speedModel == "快速") {
                //更改用户绘图可用次数
                this.computNum(userData);
              }
            } else {
              this.$message({ message: res.description, type: "warning" });
            }
          });
        }
      } else {
        this.$message({
          message: "提示词不能为空哦~",
          type: "warning",
        });
      }
    },

    //根据ID获取实时图片和进度
    getTaskById(code) {
      this.apiEndTime = new Date();

      let timeDiff = Math.abs(
        this.apiEndTime.getTime() - this.apiStartTime.getTime()
      );
      let diffDays = Math.ceil(timeDiff / 1000);
      if (diffDays > 240) {
        this.$message({
          message: "当前绘画超时，请刷新重试或联系管理员~",
          type: "warning",
        });
        this.finishDraw = true;
        return;
      }
      console.log(diffDays);

      let params = {
        id: code,
      };
      // this.taskId = code;

      fetchTaskById(params).then((res) => {
        let param = {
          url: res.imageUrl,
        };

        let progress = res.progress;
        let paramMj = {
          buttons: res.buttons,
          taskId: code,
          action: res.action,
        };
        // let status = res.status
        if (res.imageUrl) {
          // this.chatList[this.chatList.length - 1].pic = res.imageUrl;
          // this.finishDraw = true;
          this.getOssImg(param, progress, paramMj);
        }

        if (res.status == "SUCCESS") {
          this.isSend = false;

          // let buttons = res.buttons;
          // if ((buttons.length = 11)) {
          //   buttons.splice(10, 1);
          // }
          // this.chatList[this.chatList.length - 1].buttons = buttons;
          // this.scrollBottom();

          // let recordParam = {
          //   type: this.model,
          //   content: res.imageUrl,
          //   prompt: this.recordData.prompt ? this.recordData.prompt : "",
          //   userId: localStorage.getItem("MJ_USER_ID"),
          //   buttons: JSON.stringify(buttons),
          //   taskId: code,
          //   action: res.action,
          // };

          // console.log(recordParam);
          // addChatRecord(recordParam).then((res) => {
          //   console.log(this.chatList);
          // });

          // setTimeout(() => {
          // this.finishDraw = false;
          // }, 1500);
        } else {
          //加载未完成时
          this.isSend = false;
          // if(res.imageUrl){
          //   this.chatList[this.chatList.length-1].pic = this.getOssImg(param);
          // }
          // this.chatList[this.chatList.length-1].prompt = res.prompt;
          // this.chatList[this.chatList.length-1].action = res.action;
          // this.chatList[this.chatList.length-1].status = res.status;
          setTimeout(() => {
            this.getTaskById(code);
          }, 2500);
        }

        //进度
        this.chatList[this.chatList.length - 1].taskId = code;
        this.chatList[this.chatList.length - 1].prompt = res.prompt;
        this.chatList[this.chatList.length - 1].action = res.action;
        this.chatList[this.chatList.length - 1].status = res.status;
      });
    },

    //绘图变化
    UChange(btn, item) {
      // console.log(btn);
      // return;
      if (btn.label == "Vary (Subtle)") {
        this.$message({
          message: "此功能暂未开放，请耐心等待哦~",
          type: "warning",
        });
        return;
      }
      if (btn.label == "Vary (Region)") {
        this.$message({
          message: "此功能暂未开放，请耐心等待哦~",
          type: "warning",
        });
        return;
      }
      if (btn.label == "Custom Zoom") {
        this.$message({
          message: "此功能暂未开放，请耐心等待哦~",
          type: "warning",
        });
        return;
      }
      if (!this.finishDraw) {
        this.$message({
          message: "图片正在努力绘画中，请等待绘画完成后再次绘画~",
          type: "warning",
        });
        return;
      }
      this.apiStartTime = new Date();

      this.loading = true;
      this.isSend = true;
      this.finishDraw = false;
      let chatGPT = {
        headImg: require("@/assets/img/head_portrait1.jpg"),
        name: "Midjourney Proxy",
        time: getdateTime(),
        msg: null,
        chatType: 0, //信息类型，0文字，1图片
        uid: "1002", //uid
        prompt: null,
        pic: null,
        pic2: null,
        showPic: null,
        showPic2: null,
        cueWord: item.cueWord,
        progress: null,
        action: null,
        taskId: null,
        buttons: null,
        status: null,
      };
      this.inputMsg = "";
      this.sendMsg(chatGPT);

      let params = {
        customId: btn.customId,
        state: null,
        taskId: item.taskId,
      };

      //绘图变化接口
      imageChange(params).then((res) => {
        if (res.code == "21") {
          this.$message({ message: res.description, type: "warning" });
          return;
        }
        this.getTaskById(res.result);
      });
    },

    //更改用户的绘画可用次数
    computNum(userData) {
      let param = {
        id: userData.id,
      };
      updateUserchatCount(param).then((res) => {
        if (res.code == 200) {
          this.$store.commit("handelChatCount");
        } else {
          this.$message({ message: res.message, type: "warning" });
        }
      });
    },

    //根据oss获取图片
    getOssImg(param, progress, paramMj) {
      uploadOssImg(param).then((result) => {
        this.$nextTick(() => {
          console.log(progress);

          this.chatList[this.chatList.length - 1].pic = result.data;
          this.chatList[this.chatList.length - 1].progress = progress;

          // this.chatList[this.chatList.length-1].status = status;
          this.scrollBottom();

          if (progress == "100%") {
            this.finishDraw = true;

            let buttons = paramMj.buttons;
            if ((buttons.length = 11)) {
              buttons.splice(10, 1);
            }
            this.chatList[this.chatList.length - 1].buttons = buttons;
            this.scrollBottom();

            let recordParam = {
              type: this.model,
              content: result.data,
              prompt: this.recordData.prompt ? this.recordData.prompt : "",
              userId: localStorage.getItem("MJ_USER_ID"),
              buttons: JSON.stringify(buttons),
              taskId: paramMj.taskId,
              action: paramMj.action,
            };

            console.log(recordParam);
            addChatRecord(recordParam).then((res) => {
              console.log(this.chatList);
            });
          }
        });
      });
    },

    //点击垫图确定按钮
    sureOnePic() {
      this.onePicVisible = false;
      let chatGPT = {
        headImg: require("@/assets/img/head_portrait1.jpg"),
        name: "Midjourney Proxy",
        time: getdateTime(),
        msg: null,
        chatType: 0, //信息类型，0文字，1图片
        uid: "1002", //uid
        prompt: null,
        pic: this.onePicUrl,
        pic2: null,
        showPic: "http:xuanchi:8083/mj/image/" + new Date().getTime(),
        showPic2: null,
        cueWord: "",
        progress: null,
        action: "FORSHADOW",
        taskId: null,
        buttons: null,
        status: null,
      };
      this.inputMsg = "";
      this.sendMsg(chatGPT);
    },

    //点击混图确定按钮
    sureTwoPic() {
      this.twoPicVisible = false;
      let chatGPT = {
        headImg: require("@/assets/img/head_portrait1.jpg"),
        name: "Midjourney Proxy",
        time: getdateTime(),
        msg: null,
        chatType: 0, //信息类型，0文字，1图片
        uid: "1002", //uid
        prompt: null,
        pic: this.twoPicUrl,
        pic2: this.twoPicUrl2,
        showPic: "http:xuanchi:8083/mj/image/" + new Date().getTime(),
        showPic2: "http:xuanchi:8083/mj/image/" + new Date().getTime() + 20,
        cueWord: "",
        progress: null,
        action: "FORSHADOWS",
        taskId: null,
        buttons: null,
        status: null,
      };
      this.sendMsg(chatGPT);
    },

    //点击描述图确定按钮
    sureDesPic() {
      this.desPicVisible = false;
      let chatGPT = {
        headImg: require("@/assets/img/head_portrait1.jpg"),
        name: "Midjourney Proxy",
        time: getdateTime(),
        msg: null,
        chatType: 0, //信息类型，0文字，1图片
        uid: "1002", //uid
        prompt: null,
        pic: this.desPicUrl,
        pic2: null,
        showPic: "http:xuanchi:8083/mj/image/" + new Date().getTime(),
        showPic2: null,
        cueWord: "",
        progress: null,
        action: "FORSHADOWDES",
        taskId: null,
        buttons: null,
        status: null,
      };
      this.sendMsg(chatGPT);
    },

    //混图时插入1图片
    insertBaseUrl1(item) {
      if (!this.inputMsg) {
        this.inputMsg = item.showPic + ",";
        this.insertBaseAble1 = true;
      } else {
        let inputMsgArr = this.inputMsg.split(",");
        for (let i in inputMsgArr) {
          if (!inputMsgArr[i]) {
            inputMsgArr.splice(i, 1);
          }
        }
        if (inputMsgArr.length == 1) {
          this.inputMsg += item.showPic;
        }

        if (inputMsgArr.length == 2) {
          this.insertBaseAble1 = true;
          this.insertBaseAble2 = true;
          return;
        }
        console.log(inputMsgArr);
      }

      this.model = "3";
    },

    //混图时插入2图片
    insertBaseUrl2(item) {
      if (!this.inputMsg) {
        this.inputMsg = item.showPic2 + ",";
        this.insertBaseAble2 = true;
      } else {
        let inputMsgArr = this.inputMsg.split(",");
        for (let i in inputMsgArr) {
          if (!inputMsgArr[i]) {
            inputMsgArr.splice(i, 1);
          }
        }
        if (inputMsgArr.length == 1) {
          this.inputMsg += item.showPic2;
        }

        if (inputMsgArr.length == 2) {
          this.insertBaseAble1 = true;
          this.insertBaseAble2 = true;
          return;
        }
        console.log(inputMsgArr);
      }
    },

    //插入图转base64
    insertBaseUrlDes(item) {
      if (this.model == "4") {
        this.desPicUrl = item.pic;
        this.inputMsg = item.showPic;
        this.model = "4";
      }
    },

    //插入图转base64
    insertBaseUrl(item) {
      if (this.model == "1" || this.model == "2") {
        this.onePicUrl = item.pic;
        this.inputMsg = item.showPic + ",";
        this.model = "2";
      }
    },

    getSpeedModel() {
      if (this.speedModel == "快速") {
        return "fast";
      }
      if (this.speedModel == "放松") {
        return "relax";
      }
    },

    showBigImage(e) {
      if (e != "") {
        this.photoVisible = true;
        this.bigImgUrl = e;
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.relaxBtn .el-radio-button__inner {
  border-radius: 15px 0 0 15px !important;
}
/deep/.fastBtn .el-radio-button__inner {
  border-radius: 0 15px 15px 0 !important;
}
</style>

<style lang="scss" scoped>
.flash_cursor {
  width: 20px;
  height: 30px;
  display: inline-block;
  background: #d6e3f5;
  opacity: 1;
  animation: glow 800ms ease-out infinite alternate;
}

@keyframes glow {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.5;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.custom-class .menu_item__available:hover,
.custom-class .menu_item_expand {
  background: #ffecf2 !important;
  color: #ff4050 !important;
}

.chat-window {
  width: 100%;
  height: 100%;
  margin-left: 20px;
  position: relative;

  .top {
    // margin-bottom: 50px;

    &::after {
      content: "";
      display: block;
      clear: both;
    }

    .head-pic {
      float: left;
    }

    .info-detail {
      float: left;
      margin: 5px 20px 0;

      .name {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
      }

      .detail {
        color: #9e9e9e;
        font-size: 12px;
        margin-top: 2px;
      }
    }

    .other-fun {
      float: right;
      margin-top: 20px;

      span {
        margin-left: 30px;
        cursor: pointer;
      }

      // .icon-tupian {

      // }
      input {
        display: none;
      }
    }
  }

  .botoom {
    width: 100%;
    height: 100vh;
    background-color: rgb(50, 54, 68);
    border-radius: 20px;
    // padding: 15px;
    box-sizing: border-box;
    position: relative;

    .chat-content {
      width: 100%;
      height: calc(100vh - 100px);
      overflow-y: scroll;
      // padding: 20px 0px 20px 0px;
      box-sizing: border-box;
      scroll-behavior: smooth;
      background-color: #313338;

      /* 整个滚动条 */
      &::-webkit-scrollbar {
        /* 对应纵向滚动条的宽度 */
        width: 10px;
        /* 对应横向滚动条的宽度 */
        height: 0px;
      }

      /* 滚动条上的滚动滑块 */
      &::-webkit-scrollbar-thumb {
        background-color: #1a1b1e;
        border-radius: 32px;
      }

      /* 滚动条轨道 */
      &::-webkit-scrollbar-track {
        background-color: #444444;
        border-radius: 32px;
      }

      // &::-webkit-scrollbar {
      //   width: 0;
      //   /* Safari,Chrome 隐藏滚动条 */
      //   height: 0;
      //   /* Safari,Chrome 隐藏滚动条 */
      //   display: none;
      //   /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
      // }

      .chat-wrapper {
        position: relative;
        word-break: break-all;
        margin-bottom: 5px;

        .chat-friend {
          width: 100%;
          height: 100%;
          // float: left;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          background-color: #444037;
          border-left: 3px solid #f0b132;

          &:hover {
            background-color: rgb(39, 42, 55);
          }

          .chat-text {
            max-width: 90%;
            padding: 20px;
            border-radius: 20px 20px 20px 5px;
            // background-color: rgb(56, 60, 75);
            color: #fff;

            pre {
              white-space: break-spaces;
            }
          }

          .chat-img {
            img {
              width: 100px;
              height: 100px;
            }
          }

          .info-time {
            margin: 10px 5px 0px 10px;
            color: #fff;
            font-size: 14px;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              vertical-align: middle;
              margin-right: 10px;
            }

            span:last-child {
              color: rgb(101, 104, 115);
              margin-left: 10px;
              vertical-align: middle;
            }
          }
        }

        .chat-me {
          width: 100%;
          float: right;
          margin-bottom: 20px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;

          .chat-text {
            float: right;
            max-width: 90%;
            padding: 20px;
            border-radius: 20px 20px 5px 20px;
            background-color: rgb(29, 144, 245);
            color: #fff;

            &:hover {
              background-color: rgb(26, 129, 219);
            }
          }

          .chat-img {
            img {
              max-width: 300px;
              max-height: 200px;
              border-radius: 10px;
            }
          }

          .info-time {
            margin: 10px 0;
            color: #fff;
            font-size: 14px;
            display: flex;
            justify-content: flex-end;

            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              vertical-align: middle;
              margin-left: 10px;
            }

            span {
              line-height: 30px;
            }

            span:first-child {
              color: rgb(101, 104, 115);
              margin-right: 10px;
              vertical-align: middle;
            }
          }
        }
      }
    }

    .chatInputs {
      width: 99%;
      bottom: 0;
      margin: 25px 5px 0 5px;
      display: flex;
      height: 55px;
      justify-content: center;

      .boxinput {
        width: 50px;
        height: 50px;
        background-color: rgb(66, 70, 86);
        border-radius: 15px;
        border: 1px solid rgb(80, 85, 103);
        position: relative;
        cursor: pointer;

        img {
          width: 30px;
          height: 30px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .emoji {
        transition: 0.3s;

        &:hover {
          background-color: rgb(46, 49, 61);
          border: 1px solid rgb(71, 73, 82);
        }
      }

      .inputs {
        width: 90%;
        height: 50px;
        background-color: rgb(66, 70, 86);
        border-radius: 15px;
        border: 2px solid rgb(34, 135, 225);
        padding: 10px;
        box-sizing: border-box;
        transition: 0.2s;
        font-size: 20px;
        color: #fff;
        font-weight: 100;
        margin: 0 20px;

        &:focus {
          outline: none;
        }
      }

      .send {
        background-color: rgb(29, 144, 245);
        border: 0;
        transition: 0.3s;
        box-shadow: 0px 0px 5px 0px rgba(0, 136, 255);

        &:hover {
          box-shadow: 0px 0px 10px 0px rgba(0, 136, 255);
        }
      }
    }
  }
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px !important;
  text-align: center;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
}
</style>