<template>
    <div>
      
      <div calss="payTop">
        <div class="yearMonth" :style="getCheckYearStyle()" @click="clickYear()">
          <span class="tejia">限时特价</span>
          <div>
            <span class="typeVip">绘画年度会员</span>
          </div>
          <div style="margin-top: 15px;">
            <span class="nowMoney"><span style="font-size: 12px;" >￥</span>398</span>
            <span class="zhekou">2折</span>
            <p class="lastMoney">￥1998</p>
          </div>
          <div class="typeDay">
            <span style="font-size: 14px;font-weight: bold;">快速生图一千次</span>
            <span style="font-size: 12px;font-weight: bold;">慢速一年不限次</span>
          </div>
        </div>


        <div class="threeMonth" :style="getCheckThreeStyle()" @click="clickThree()">
          <span class="tejia">最多人选择</span>
          <div>
            <span class="typeVip">绘画季度会员</span>
          </div>
          <div style="margin-top: 15px;">
            <span class="nowMoney"><span style="font-size: 12px;">￥</span>168</span>
            <span class="zhekou">3折</span>
            <p class="lastMoney">￥498</p>
          </div>
          <div class="typeDay">
            <span style="font-size: 14px;font-weight: bold;">快速生图四百次</span>
            <span style="font-size: 12px;font-weight: bold;">慢速一季不限次</span>
          </div>
        </div>


        <div class="month" :style="getCheckMonthStyle()" @click="clickMonth()">
          <span class="typeVip">绘画月度会员</span>
          <div style="margin-top: 15px;">
            <span class="nowMoney"><span style="font-size: 12px;">￥</span>49</span>
            <span class="zhekou">4折</span>
            <p class="lastMoney">￥128</p>
          </div>
          <div class="typeDay">
            <span style="font-size: 14px;font-weight: bold;">快速生图一百次</span>
            <span style="font-size: 12px;font-weight: bold;">慢速一月不限次</span>
          </div>
        </div>
      </div>

      <div style="display: flex;flex-direction: column;align-items: flex-end;">
        <div style="width: 140px;">
          <span style="color: rgb(251, 65, 44);padding-bottom: 5px;">实付金额：￥<span style="font-size: 22px;font-weight: bold;">{{ this.ruleForm.payMoney }}</span></span>

        </div>

        <div style="padding: 10px;margin-top: 15px;">
          <el-form
                :model="ruleForm"
                status-icon
                :rules="rules"
                ref="ruleForm"
                label-position="left"
                class="login-form"
            >
              <el-row :gutter="10">
                <el-col :span="15">
                  <el-form-item prop="phone">
                    <div class="grid-content bg-purple"><el-input v-model="ruleForm.phone" placeholder="绑定VIP手机号" :readonly="timer > 0" @change="changeIphone"></el-input></div>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <div class="grid-content bg-purple">
                    <el-button type="primary" @click="getSmsCodeFun" :disabled="timer > 0">
                      <span >
                        {{ timer > 0 ? timer + ' 秒后重新发送' : '发送验证码' }}
                      </span>
                    </el-button>
                    
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="10">
                  <el-form-item prop="verCode" >
                    <div class="grid-content bg-purple"><el-input v-model="ruleForm.verCode" placeholder="请输入验证码" ></el-input></div>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-button type="primary" @click="getPayCodeImg()" :loading="loading" v-if="timer > 0">
                  <span v-if="!loading">获取支付码</span>
                  <span v-else>获 取 中...</span>
                </el-button>
                </el-col>
              </el-row>
              <el-row :gutter="2" >
                
              </el-row>
              <!--  -->
            </el-form>
        </div>
      </div>

      <div v-if="showCodeImg">
        <div id="qrcode_box" ref="qrCodeUrl" style="margin: 0 auto;width: 100px;"></div>
        <span style="padding-top: 5px;text-align: center;font-size: 12px;display:block">使用<span style="font-weight: bold;">微信</span>扫码支付</span>
        <img :src="require('@/assets/img/weixinzhifu.png')" width="30px" height="30px" style="display:block;margin: 0 auto;"/>
      </div>
      
    </div>
</template>

<script>

import { getUnified,getSmsCode } from "@/api/getData";
import QRCode from 'qrcodejs2'
export default {
  data() {
    const checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('手机号不能为空'));
        } else {
          //验证手机号
          const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
          //验证区号
          const phoneReg = /^\d{3}-\d{8}|\d{4}-\d{7}$/ 
          if (reg.test(value)||phoneReg.test(value)) {
            callback();
          } else {
            return callback(new Error('请输入正确的手机号码！'));
          }
        }
    }
    return {
      // ws是否启动
      wsIsRun: false,
      // 定义ws对象
      webSocket: null,
      // ws请求链接（类似于ws后台地址）
      ws: '',
      // ws定时器
      wsTimer: null,

      timer: 0, //计时器

      vipCheck:"1",
      smsCode:"",//短信验证码
      showCodeImg:false,//展示付款码
      loading:false,//获取微信付款码按钮等待
      ruleForm: {
        phone: "",
        verCode: "",
        vipCheck: "1",
        payMoney:"398",
      },
      rules: {
        phone: [
          { required: true, message: "手机号不能为空！", trigger: "blur" },
          { validator: checkPhone, trigger: 'blur'}
        ],
        verCode: [
          { required: true, message: "验证码不能为空！", trigger: "blur" },
        ],
      },
      
      
    }
  },
  mounted(){
    
  },

  methods: {
    // openUpgrade(){
    //   this.loading = false;
    //   this.showCodeImg = false;
      
    //   this.ruleForm.phone = "";
    //   this.vipCheck = "1";
    //   this.payMoney = "398";

    // },
    changeIphone(){
      this.showCodeImg = false;
      this.onbeforeunload();
    },

    //获取当前选中会员种类样式
    getCheckYearStyle(){
      if(this.vipCheck == '1'){
        let style = {
          "border": "2px #f0d09e solid",
          "background": "#fefbf4",
        }
        return style;
      }
        
    },

    //获取当前选中会员种类样式
    getCheckThreeStyle(){
      if(this.vipCheck == '2'){
        let style = {
          "border": "2px #f0d09e solid",
          "background": "#fefbf4",
        }
        return style;
      }
    },

    //获取当前选中会员种类样式
    getCheckMonthStyle(){
      if(this.vipCheck == '3'){
        let style = {
          "border": "2px #f0d09e solid",
          "background": "#fefbf4",
        }
        return style;
      }
    },

    //点击选中年度会员时
    clickYear(){
      this.vipCheck = '1';
      this.ruleForm.vipCheck = '1';
      this.ruleForm.payMoney = "398";
      this.changeIphone();

    },

    //点击选中季度会员时
    clickThree(){
      this.vipCheck = '2';
      this.ruleForm.vipCheck = '2';
      this.ruleForm.payMoney = "168";
      this.changeIphone();

    },

    //点击选中月度会员时
    clickMonth(){
      this.vipCheck = '3';
      this.ruleForm.vipCheck = '3';
      this.ruleForm.payMoney = "49";
      this.changeIphone();

    },

    //获取短信验证码
    getSmsCodeFun() {
      if(!this.ruleForm.phone){
        this.$message({message: "请输入手机号~",type: "warning",});
        return;
      }
      //验证手机号
      const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
      //验证区号
      const phoneReg = /^\d{3}-\d{8}|\d{4}-\d{7}$/ 
      if (reg.test(this.ruleForm.phone)||phoneReg.test(this.ruleForm.phone)) {
        if (this.timer === 0) {
          this.showCodeImg = false;
          this.timer = 60;
          this.ruleForm.verCode = "";
          const interval = setInterval(() => {
            this.timer--;
            if (this.timer === 0) {
              clearInterval(interval);
            }
          }, 1000);

          let param = {
            phone:this.ruleForm.phone
          }
          getSmsCode(param).then((res) => {
            this.smsCode = res.data;

          })
        }
      } else {
        this.$message({message: "请输入正确的手机号码~",type: "warning",});
        return;
      }

      
    },

    //获取微信付款码
    getPayCodeImg(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if(this.smsCode != this.ruleForm.verCode){
            this.$message({message: "您的手机验证码有误，请核对后再输入~",type: "warning",});
            return;

          }
          
          this.loading = true;
          this.showCodeImg = false;
          getUnified(this.ruleForm).then((res) => {
            this.loading = false;
            this.showCodeImg = true;
            this.$nextTick(() => {
              this.creatQrCode(res.data);
              // this.wsIsRun = true;
              // this.wsInit();
              if ('WebSocket' in window) {
                // this.websocket = new WebSocket('wss://mj.xuanchiai.com/wss/ws/awdMatch/' + this.ruleForm.phone)
                this.websocket = new WebSocket('ws://10.28.2.12:8083/ws/awdMatch/' + this.ruleForm.phone)
                this.initWebSocket()
              } else {
                alert('当前浏览器 Not support websocket')
              }
            })

          })
        }
      })

    },

    creatQrCode(url) {
      const qrcode = new QRCode("qrcode_box", {
        width: 100, //二维码的宽度
        height: 100, //二维码的高度
        text: url, // 二维码地址
        colorDark: "#000", //二维码颜色
        colorLight: "#fff" //二维码背景颜色
      });
    },

    initWebSocket () {
        // 连接错误
        this.websocket.onerror = this.setErrorMessage

        // 连接成功
        this.websocket.onopen = this.setOnopenMessage

        // 收到消息的回调
        this.websocket.onmessage = this.setOnmessageMessage

        // 连接关闭的回调
        this.websocket.onclose = this.setOncloseMessage

        // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
        window.onbeforeunload = this.onbeforeunload
    },
    setErrorMessage () {
      console.log('WebSocket连接发生错误   状态码：' + this.websocket.readyState)
    },
    setOnopenMessage () {
      console.log('WebSocket连接成功    状态码：' + this.websocket.readyState)
    },
    setOnmessageMessage (event) {
      // 根据服务器推送的消息做自己的业务处理
      console.log('服务端返回：' + event.data);
      localStorage.setItem('MJ_USER_ID', event.data);
      this.$emit("upgradeLoad");
      this.$emit("closeUpgrade");
    },
    setOncloseMessage () {
      console.log('WebSocket连接关闭    状态码：' + this.websocket.readyState)
    },
    onbeforeunload () {
      this.closeWebSocket()
    },
    closeWebSocket () {
      this.websocket.close()
    }
  },
  beforeDestroy () {
    this.onbeforeunload()
  }

    
}
</script>

<style>
.typeDay{
  text-align: center;line-height: 2;font-size: 14px;color: rgb(196, 37, 18);
}
.typeVip{
  color: rgb(50, 50, 50);font-size: 16px;font-weight: bold;padding-top: 10px;
}
.lastMoney{
  font-size: 13px;color: rgb(169, 169, 169);text-decoration: line-through;margin-top: 25px;
}
.zhekou{
  display: inline-block;background: rgb(51, 204, 153);color: rgb(255, 255, 255);font-size: 11px;padding: 0px 5px;margin-left: 5px;border-radius: 10px;font-weight: lighter;height: 20px;line-height: 20px;
}
.nowMoney{
  font-size: 18px;font-weight: bold;color: rgb(247, 56, 39);padding: 16px 0px 10px;
}
.payTop{
  overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    margin: auto;
    padding-bottom: 20px;
    padding-top: 25px;
    text-align: center;
}
.month{
  margin: 5px;
  border-radius: 10px;
  padding: 20px 10px;
  text-align: center;
  width: 100px;
  height: 150px;
  display:inline-block;
  border:2px #dbdbdb solid;
  position:relative;
}
.threeMonth{
  margin: 5px;
  border-radius: 10px;
  padding: 20px 10px;
  text-align: center;
  width: 100px;
  height: 150px;
  display:inline-block;
  border:2px #dbdbdb solid;
  position:relative;
}
.yearMonth{
  margin: 5px;
  border-radius: 10px;
  padding: 20px 10px;
  text-align: center;
  width: 100px;
  height: 150px;
  display:inline-block;
  /* border: 2px #f0d09e solid; */
  border:2px #dbdbdb solid;
  position:relative;
}

.tejia{
  position: absolute;
  top: -10px;
  left: 0px;
  background: rgb(251, 65, 44);
  color: rgb(238, 239, 181);
  font-size: 12px;
  padding: 0px 10px;
  border-radius: 20px;
  height: 20px;
  line-height: 20px;
}

</style>