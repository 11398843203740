<template>
    <!-- <div class="body"> -->
        <div class="login">
            <!-- <div class="avatar">
                <img src="avatar.png" />
            </div> -->
            <!-- <h2>登录</h2> -->
            <!-- <h3>Welcome back</h3> -->

            <el-form
                :model="ruleForm"
                status-icon
                :rules="rules"
                ref="ruleForm"
                label-position="left"
                class="login-form"
            >
            <div class="textbox">
                <el-form-item prop="phone" >
                    <el-input v-model="ruleForm.phone" placeholder="手机号" class="phone"></el-input>
                </el-form-item>
                <!-- <el-form-item  prop="verCode" style="width:50%">
                    <el-input v-model="ruleForm.verCode" placeholder="验证码" class="verCode"></el-input>
                </el-form-item> -->
            </div>
            
            <el-button class="button" :loading="loading" @click="submitForm">
                <span v-if="!loading">登 录</span>
                <span v-else>登 录 中...</span>
            </el-button>
            </el-form>

            <!-- <form class="login-form">
                <div class="textbox">
                    <input placeholder="Username" />
                    <span class="material-symbols-outlined"> account_circle </span>
                </div>
            </form> -->
        </div>
    <!-- </div> -->
  </template>
  
  <script>
  
  import { login } from "@/api/getLogin";
  import { getToken,setToken } from '@/util/auth'
  export default {
    data() {
        
    const checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('手机号不能为空'));
        } else {
          //验证手机号
          const reg = /^1[3456789]\d{9}$/
          //验证区号
          console.log(reg.test(value));
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error('请输入正确的手机号码！'));
          }
        }
    }
      return {
        loading:false,
        ruleForm: {
            phone: "",
            verCode: "",
        },
        rules: {
          phone: [
            { required: true, message: "手机号不能为空！", trigger: "blur" },
            { validator: checkPhone, trigger: 'blur'}
          ],
          verCode: [
            { required: true, message: "验证码不能为空！", trigger: "blur" },
          ],
        },
      };
    },
    methods: {
      submitForm() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.loading = true;
            let that = this;
            login(this.ruleForm).then((res) => {
              if(res.code == 200){
                localStorage.setItem('MJ_USER_ID', res.data.id);
                localStorage.setItem('model', '1');
                this.$emit('loginFun');
                this.$message({message: "登录成功，升级为VIP可享受更多绘画机会~",type: "success",});
                // let isAuthenticated = getToken();
                // this.$router.push("/home");
                // this.$router.go(0);
                this.$emit('getLocalStorageUser');
                // console.log(isAuthenticated);
                
                // setToken(JSON.stringify(res.data));
              }else{
                this.$message({message: res.message,type: "warning",});
              }
            }).finally(function(){
              that.loading = false;
            });

          }
        });
      },
      resetForm() {
        this.$refs.ruleForm.resetFields();
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
    /deep/.el-input {
        // 输入框高度
        height: 52px;
        // 边框圆角
        border-radius: 8px;
    }
    /deep/.el-input__inner {
        height: 52px;
        background: transparent;
        border: 2px solid rgba(255, 255, 255, 0.1);
        font-size: 18px;
        // 设置字号
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        // 设置输入字体的颜色
        color: inherit;
    }
  </style>


  <style src="@/view/login/styles.css"  scoped></style>
