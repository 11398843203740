<template>
    <div>
      <p><span style="font-weight: 700;">1.快速绘画</span>。即Midjourney中的“/imagine”绘图。为了提高效率，此处省去该命令输入，可直接输入提示词。</p>
      <p style="margin-left: 15px;"><span style="font-weight: 600;">(1)文生图。</span>直接在输入框中输入提示词，回车即可生成图片。</p>
      <img style="margin-left: 35px;" :src="step1imgUrl" alt="" width="600px" height="100px">
      <br/>
      <br/>
      <p style="margin-left: 15px;"><span style="font-weight: 600;">(2)垫图。</span>以一张图片作为参照，输入提示词后生成类似参照图近似的风格图。</p>
      <p style="margin-left: 35px;">① 点击输入框左边的加号进入加载图片界面。</p>
      <img style="margin-left: 35px;" :src="step2imgUrl" alt="" width="600px" height="100px">
      <p style="margin-left: 35px;">② 点击垫图框中的加号加载图片后，点击确定。</p>
      <img style="margin-left: 35px;" :src="step3imgUrl" alt="" width="300px" height="300px">
      <p style="margin-left: 35px;">③ 点击插入参照图地址，在输入框的地址逗号后，输入提示词，回车即可生成图片。</p>
      <img style="margin-left: 35px;" :src="step4imgUrl" alt="" width="500px" height="450px">
      <br/>
      <br/>
      
      <p><span style="font-weight: 700;">2.多图融合</span>。即“/blend”溶图，可理解为融合多张图片的风格和要素，生成一张新图</p>
      <p style="margin-left: 15px;">①	点击输入框左边的加号进入加载多图界面。</p>
      <img style="margin-left: 35px;" :src="step2imgUrl" alt="" width="600px" height="100px">
      <p style="margin-left: 15px;">②	依次点击两个加号加载2张图片，点击确定。</p>
      <img style="margin-left: 35px;" :src="step5imgUrl" alt="" width="600px" height="360px">
      <p style="margin-left: 15px;">③ 依次点击插入地址后，在提示词输入栏回车即可生成多图融合图片。</p>
      <img style="margin-left: 35px;" :src="step6imgUrl" alt="" width="600px" height="360px">
      <br/>
      <br/>
      
      <p><span style="font-weight: 700;">3.图生文</span>。即“/describe”反推提示词。可理解为上传参考图后，反推该图片的提示词。</p>
      <p style="margin-left: 15px;">①	点击输入框左边的加号进入加载图片界面。</p>
      <img style="margin-left: 35px;" :src="step2imgUrl" alt="" width="600px" height="100px">
      <p style="margin-left: 15px;">② 点击描述框中的加号加载图片后，点击确定。</p>
      <img style="margin-left: 35px;" :src="step3imgUrl" alt="" width="300px" height="300px">
      <p style="margin-left: 15px;">③ 点击插入地址，回车即可生成图生文结果。</p>
      <img style="margin-left: 35px;" :src="step7imgUrl" alt="" width="500px" height="450px">
      
    </div>
    
</template>

<script>
export default {
  data() {
    return {
      step1imgUrl:require("@/assets/img/tutorial/Snap11229.jpg"),
      step2imgUrl:require("@/assets/img/tutorial/Snap11231.jpg"),
      step3imgUrl:require("@/assets/img/tutorial/Snap11234.jpg"),
      step4imgUrl:require("@/assets/img/tutorial/Snap11242-1.jpg"),
      step5imgUrl:require("@/assets/img/tutorial/Snap11237.jpg"),
      step6imgUrl:require("@/assets/img/tutorial/Snap11238.jpg"),
      step7imgUrl:require("@/assets/img/tutorial/Snap11240.jpg"),
      
      
    }
  }
}
</script>

<style>

</style>