import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

// 创建一个新的 store 实例
const store = new Vuex.Store({
    state() {
        return {
            userInfo: {
                chatCount:0,
                id:"",
                ip:"",
                level: "",
                phone: "",
                vipEndTime:""
            },
        }
    },
    getters: {
        chatCount: state => state.userInfo.chatCount
      },
    mutations: {
        settUserInfo(state,userInfo) {
            state.userInfo = userInfo;
        },
        handelChatCount(state) {
            state.userInfo.chatCount--;
        },
    }
})
export default store