import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import VueRouter from 'vue-router'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index'
import "./mock/index.js"

import Viewer from 'v-viewer'
import store from './store/index'

import 'viewerjs/dist/viewer.css'

import tool from '@/util/weblocalStorage'
import VueCookies from 'vue-cookies'

// import VueContextMenu from 'vue-contextmenu'
// Vue.use(VueContextMenu)

import Contextmenu from "vue-contextmenujs"
Vue.use(Contextmenu);

Vue.use(VueCookies)
Vue.use(tool)

Vue.use(Viewer)

Viewer.setDefaults({

Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }

})

Vue.use(VueRouter)
Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  store: store,
  router,
  render: h => h(App),
}).$mount('#app')
