import VueRouter from 'vue-router'
 
import ChatHome from '../view/pages/chatHome/index.vue'
import Video from '../view/pages/video.vue'
import Lingting from '../view/pages/lingting.vue'
import Setting from '../view/pages/setting.vue'
import Login from '../view/login/login.vue'
import Home from '../view/home.vue'
import Tutorial from '../view/pages/tutorial/index.vue'
import ChatWindow from '../view/pages/chatHome/chatwindow.vue'

const router = new VueRouter({
    // mode: 'history',    // 路由模式，该模式不会在地址中显示井号#
    routes: [
        {
            path: "/",
            redirect: "/Home",
          },
        {
            path: "/Login",
            name: "Login",
            component: Login,
        },  
        {
            path: "/Home",
            name: "Home",
            component: Home,
        },  
        {
            path: "/ChatHome",
            name: "ChatHome",
            component: ChatHome,
        },
        {
            path: "/Tutorial",
            name: "Tutorial",
            component: Tutorial,
        },  

        {
            path: "/Video",
            name: "Video",
            component: Video
        }, 
        {
            path: "/Lingting",
            name: "Lingting",
            component: Lingting
        },   
        {
            path: "/Setting",
            name: "Setting",
            component: Setting
        },    
    ]
})

// 导航守卫，前置处理
// router.beforeEach((to, from, next) => {
//     // let isAuthenticated = !!sessionStorage.getItem('userInfo')
//     let isAuthenticated = !!getToken();
//     // 如果路由要跳转到除了登录和注册的界面的话就判断是否已经登录，如果没有登录就强制跳到登录界面
//     if (to.path !== '/login' && to.path !== '/register' && !isAuthenticated) {
//         next({ path: '/login' })
//         Message({
//             message: '请先登录！',
//             type: "warning",
//         });
//     } else next()
// })

export default router;