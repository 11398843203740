<template>
  <div class="home">
    <el-container height="100%">
      <el-aside width="220px">
        <Nav @resetChat="resetChat"></Nav>
      </el-aside>
      <el-main style="height: 100vh;overflow: hidden;">
        <!-- <router-view></router-view> -->
        <chatHome ref="chatHome"></chatHome>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Nav from "../components/Nav.vue";
import chatHome from "@/view/pages/chatHome/index.vue";
export default {
  name: "App",
  components: {
    Nav,
    chatHome,
  },
  methods: {
    resetChat(){
        this.$refs.chatHome.resetChat();
    },
  }
};
</script>

<style lang="scss" scoped>
.home {
  width: 100vw;
  height: 100vh;
  background-color: rgb(8 11 22);
  // border-radius: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>